import React, { Component } from 'react';
import { translate } from "react-i18next";
import { NavLink  } from 'react-router-dom';
import Modal from 'components/modal/index.jsx';
import Config from 'config.js';
import './index.scss';
import IconCheck from 'static/home/icons/check.svg';
import { Form } from 'react-form';
import HintTextField from 'components/forms/hint-text-field';
import { Validation } from 'shared/Validation';
import AssetHelper from 'AssetHelper';
import { Api } from 'shared/api';
import axios from "axios";
import Iframe from 'react-iframe';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Classnames from "classnames";
import Spinner from 'components/spinner';

let staticFileAlias = '';
if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('gamma.evryplace')) {
  staticFileAlias = window.location.origin + '/home/';
} else {
  if (window.location.pathname.includes('/p/')) {
    staticFileAlias = '../';
  }
}

const style = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftRow: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  testimonialAuthor: {
    color: '#4D4D4D',
    fontWeight: 'bold',
    fontSize: '20px',
   },
   testimonialDescription: {
      textAlign: 'center',
      fontWeight: '800',
      color: '#666666',
      padding: '0px',
   },
  horizontalYellowDivider: {
    width: '70px',
    height: '3px',
    margin: '25px',
    backgroundColor: '#fdec2b',
  },
  rightRow: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px'
    }
  },
  verticalGreyDivider: {
    width: '3px',
    margin: '0px 100px',
    backgroundColor: '#e9e9e9',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  }
})

class RegistrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialsLoading: true,
    };
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleFbLogin = this.handleFbLogin.bind(this);
  }
  onSubmit(values) {
    let { email, password, displayName } = values;
    email = email.replace(/\s/g, '');

    this.props.onSubmit({data: { email, password, displayName }});
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.registrationOpened && nextProps.registrationOpened) {
      window.scrollTo(0,0);
    }
  }
  componentDidMount () {
    setTimeout(() => {
      this.setState({ socialsLoading: false });
    }, 2000);
    window.document.addEventListener('googleLoginEvent', this.handleGoogleLogin, false);
    window.document.addEventListener('fbLoginEvent', this.handleFbLogin, false);
  }
  componentWillUnmount() {
    window.document.removeEventListener('googleLoginEvent', this.handleGoogleLogin);
    window.document.removeEventListener('fbLoginEvent', this.handleFbLogin);
  }
  handleGoogleLogin(e) {
    axios.post(Api.auth.google, e.detail).then(
      () => {
        this.props.fetchUser();
        this.props.onClose();
      },
      error => console.log(error)
    );
  }
  handleFbLogin() {
    var fbLoginUrl = Api.auth.facebook;
    var fbRedirectUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=768848760910245&redirect_uri=${window.location.origin + fbLoginUrl}&state=123&scope=email,public_profile`;
    window.open(fbRedirectUrl, '_self');
  }
  render() {
    const { t, classes, open, onClose, user } = this.props,
      validatePasswords = (values) => {
        return (passwordConfirmation) => Validation.passwordsEqual(values.password, passwordConfirmation);
      },
      socialMediaRedirectURI = Config.baseUrl + Config.home.socialMediaRedirectURI,
      facebookLoginLink = `https://www.facebook.com/dialog/oauth?client_id=${Config.home.facebookId}&redirect_uri=${socialMediaRedirectURI}`,
      googleLoginLink =   `https://accounts.google.com/o/oauth2/auth?client_id=${Config.home.googleId}&response_type=code&redirect_uri=${socialMediaRedirectURI}&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile`,
      linkedInLoginLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${Config.home.linkedInId}&redirect_uri=${socialMediaRedirectURI}&state=hujfsdn09rt784390umotjgwy903bmu09uivsr09i&scope=r_liteprofile%20r_emailaddress`;
    const testimonials = [
      {image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-1.png'), author: t('home.carouselItemsOne'), description: t('home.carouselContentOne')},
      {image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-2.png'), author: t('home.carouselItemsTwo'), description: t('home.carouselContentTwo')},
      {image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-3.png'), author: t('home.carouselItemsThree'), description: t('home.carouselContentThree')},
    ]

    const randomTestimonial = Math.round(Math.random() * 2)


    let status, // Modal status initializations
      errorStatus;

    if (user.state == 'signedUp') {
      status = <div className="input-row status"><IconCheck /><p>Signed Up!</p></div>;
    }

    if (user.errorMsg) {
      errorStatus = <div className="input-row error-status"><p>{user.errorMsg}</p></div>;
    }

    const { socialsLoading } = this.state;

    return (
      <Modal open={open} onClose={onClose} className="registration-modal" testimonials={true}>
        <Grid container wrap='nowrap' className={classes.container}>
          <Grid container direction='column' justify='center' alignItems='center' className={classes.leftRow}>
            <Grid item>
            <img src={testimonials[randomTestimonial].image} alt='testimonial image' />
            </Grid>
            <Grid item className={classes.testimonialAuthor}>
              {testimonials[randomTestimonial].author}
            </Grid>
            <Grid item><div className={classes.horizontalYellowDivider}></div></Grid>
            <Grid item className={classes.testimonialDescription}>
            {testimonials[randomTestimonial].description}
            </Grid>
          </Grid>
          <Grid item className={classes.verticalGreyDivider}></Grid>
          <Grid item className={classes.rightRow}><Form onSubmit={submittedValues => this.onSubmit(submittedValues)} validateOnSubmit={true}>
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <h3 dangerouslySetInnerHTML={{ __html: t('registration.header') }} />
                <HintTextField
                  hintText={t('registration.emailPH')}
                  field="email"
                  id="email"
                  validate={Validation.email}
                />
                <HintTextField
                  hintText={t('registration.passwordPH')}
                  field="password"
                  id="password"
                  type="password"
                  validate={Validation.password}
                />
                <HintTextField
                  hintText={t('registration.passwordConfirmPH')}
                  field="passwordConfirmation"
                  id="passwordConfirmation"
                  type="password"
                  validate={validatePasswords(formApi.values)}
                />
                <HintTextField
                  hintText={t('registration.displayNamePH')}
                  field="displayName"
                  id="displayName"
                  validate={Validation.displayName}
                />
                <button type="submit" className="registration-modal__button">{t('registration.registerBtn')}</button>
                {status}
                {errorStatus}
              </form>
            )}
          </Form>
          <div className="social__label">{t('login.orLabel')}</div>
            <div className={Classnames("social__buttons", socialsLoading ? "hodden" : '')}>
              <div id="customBtn" className="customGPlusSignIn">
                <span className="icon"></span>
                <span className="buttonText">Google</span>
              </div>
              <Iframe url={`${staticFileAlias}static/socials/socials.html`} frameBorder={0} height="80px" width="160px" />
              <Iframe url={`${staticFileAlias}static/socials/fb2.html`} frameBorder={0} height="80px" width="180px" />
            </div>
            {socialsLoading && <Spinner style={{ borderTop: '1.1em solid gray' }} />}
          <p className="registration-modal__terms">
            <span dangerouslySetInnerHTML={{ __html: t('registration.termsNotification') }} />
            <NavLink onClick={onClose} to="/terms" dangerouslySetInnerHTML={{ __html: t('registration.termsLink') }}></NavLink>
                &nbsp;{t('common.and')}&nbsp;
            <NavLink onClick={onClose} to="/privacy" dangerouslySetInnerHTML={{ __html: t('registration.privacyLink') }}></NavLink>.
          </p></Grid>
        </Grid>
      </Modal>
    );
  }
}

export default compose(
  translate('translations'),
  withStyles(style),
)(RegistrationModal);
