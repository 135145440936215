import React, {Component, Fragment } from 'react';
import {translate} from "react-i18next";
import './resetPasswordModal.scss';
import IconSent from 'static/home/icons/sent-check.svg';
import Modal from 'components/modal/index.jsx';
import Classnames from 'classnames';
import { Form } from 'react-form';
import HintTextField from 'components/forms/hint-text-field';
import { Validation } from 'shared/Validation';
import ConfirmationDialog from "../confirmationDialog/ConfirmationDialog";
class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationDialogOpen: false,
    };
    this.toggleConfirmationDialog = this.toggleConfirmationDialog.bind(this);
  }
  toggleConfirmationDialog() {
    this.setState(state => ({
      isConfirmationDialogOpen: !state.isConfirmationDialogOpen,
    }));
  }
  onSubmit(values) {
    const { onClose } = this.props;
    const { email } = values;

    this.props.onSubmit({ data: { email } });

    onClose();
    this.toggleConfirmationDialog();
  }
  render() {
    const { isConfirmationDialogOpen } = this.state;
    const {t, user, open, onClose} = this.props;

    let statusClass = Classnames({
      'status': true,
      'status--opened': !!(user && user.resetLinkSent),
      'status--closed': !(user && user.resetLinkSent),
    });

    return (
      <Fragment>
        <Modal open={open} onClose={onClose} className="reset-password-modal">
          <Form onSubmit={submittedValues => this.onSubmit(submittedValues)} validateOnSubmit={true}>
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <h3 dangerouslySetInnerHTML={{__html: t('resetPassword.header')}}/>
                <h4 dangerouslySetInnerHTML={{__html: t('resetPassword.subheader')}}/>
                <HintTextField
                  hintText={t('resetPassword.emailPH')}
                  field="email"
                  id="email"
                  validate={Validation.email}
                />
                <button type="submit" className="login button">{t('resetPassword.sendBtn')}</button>
                <div className={statusClass}><IconSent></IconSent><p>{t('resetPassword.successMsg')}</p></div>
              </form>
            )}
          </Form>
        </Modal>
        <ConfirmationDialog open={isConfirmationDialogOpen} onClose={this.toggleConfirmationDialog} modalMessage={t('resetPassword.successMsg')}/>
      </Fragment>
    );
  }
}

export default translate("translations")(ResetPasswordModal);
