import React, { Component } from "react";
import { translate } from "react-i18next";
import Modal from 'components/modal/index.jsx';
import './index.scss';
import ClassNames from 'classnames';

class RodoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkbox1: false,
      checkbox2: false,
      dirty: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }
  onSubmit(e) {
    if (this.props.onSend) {
      this.props.onSend();
    }

    e.preventDefault();
    return false;
  }
  onCheck(e) {
    this.setState({
      dirty: true,
      [e.target.name]: !this.state[e.target.name],
    });
  }
  render() {
    const { t, open } = this.props,
      { dirty, checkbox1, checkbox2 } = this.state,
      formValid = checkbox1 && checkbox2,
      statusClassName = ClassNames({
        'modal__status': true,
        'modal__status--hidden': !dirty || formValid,
      });
    const url = window.location.origin;
    const termsUrl = `${url}/terms`;

    return (
      <Modal open={open} className="rodo-modal" hideCloseButton>
        <form onSubmit={this.onSubmit}>
          <h3 className="title">{t('rodoModal.title')}</h3>
          <div className="modal__terms" dangerouslySetInnerHTML={{__html: t('rodoModal.copy1')}} />
          <div className="modal__terms">
            <span dangerouslySetInnerHTML={{__html: t('rodoModal.copy2')}} />
            <a href={termsUrl}>{t('rodoModal.copy3')}</a>.
          </div>
          <div className="modal__copy">{t('rodoModal.copy4')}</div>
          <button type="submit" className="button">{t('rodoModal.cta')}</button>
        </form>
      </Modal>
    );
  }
}

export default translate('translations')(RodoModal);
