import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from "prop-types";

const styles = theme => ({
  input: {
    marginBottom: 0,
    outline: 'none',
    borderColor: 'rgba(0,0,0,0)',
    boxSizing: 'border-box',
    color: '#666666',
    fontSize: 13,
    borderRadius: 7,
    padding: '5px 8px 5px 25px',
    backgroundImage: 'url(/static/home/icons/pic-magnifier.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '8px center',
    backgroundSize: '12px 12px',
    backgroundColor: '#EFEFEF',
    fontFamily: '"brandon", sans-serif',
    fontWeight: 400,
    height: 28,
    width: 286,
    [theme.breakpoints.down('xs')] : {
      width: '100%',
    },
  },
});

function Search({ onTextChange, text, classes, t, ...props }) {
  return (
    <input
      type="search"
      className={classes.input}
      value={text}
      onChange={(e) => onTextChange(e.target.value)}
      placeholder={t('common.search')}
      {...props}
    />
  );
}

Search.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onTextChange: PropTypes.func.isRequired,
  text: PropTypes.string
};

Search.defaultProps = {
  text: ''
};

export default compose(
  withStyles(styles),
  translate('translations')
)(Search);
