import React, { Component } from 'react';
import './index.scss';
import OwlCarousel from "react-owl-carousel2";
import Classnames from "classnames";
import { WithDependency } from 'shared/DependencyContext';
import AssetHelper, { ImageVariants } from 'AssetHelper';

class ScenesNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startPosition: 0,
      sceneName: null,
      sceneId: null,
    };

    this._onChangeScene = this._onChangeScene.bind(this);
    this._changeScene = this._changeScene.bind(this);

    let playerInstance = props.player.instance;
    playerInstance.on('changeScene', this._onChangeScene);
  }
  _onChangeScene(e) {
    this.setState({
      sceneName: e.sceneName,
      sceneId: e.sceneId,
    }, () => {
      if (this.props.onChangeScene) {
        this.props.onChangeScene(e);
      }
    });
  }
  _changeScene(sceneId) {
    const { player, changeSceneWithScenesNavEnabled } = this.props;
    if(player && changeSceneWithScenesNavEnabled) {
      player.changeScene({sceneId});
    }
  }
  render() {
    const { presentation } = this.props,
      { sceneId, startPosition } = this.state,
      visible = presentation &&
        presentation.scenes &&
        presentation.scenes.length &&
        presentation.resources &&
        presentation.resources.spheres &&
        presentation.resources.spheres.length,
      getSphereResource = (resourceId) => {
        return presentation.resources.spheres.find((res) => {
          return res.id == resourceId;
        });
      };

    if (!visible) return <div></div>;

    const scenes = presentation.scenes,
      scenesElement = scenes.map(scene => {
        let sphereResource = presentation.resources.spheres.find((res) => {
          return res.id == scene.spheres[0].resourceId;
        });

        let videoSphericalResource = presentation.resources.videosSpherical.find((res) => {
          return res.id == scene.spheres[0].videoResourceId;
        });

        let resource = sphereResource ? sphereResource : videoSphericalResource;

        if(!resource) return;

        let styles = {
          background: `url(${AssetHelper.imagePath(resource.hash, ImageVariants.THUMBNAIL)})`,
          backgroundSize: '400% 400%',
          backgroundPosition: 'center center'
        };

        const className = Classnames({
          'scene': true,
          'scene--selected': scene.id === sceneId,
        });

        return (
          <div
            className={className}
            key={scene.id}
            onClick={() => { this._changeScene(scene.id); }}
          >
            <div className="scene__background" style={styles}></div>
            <div className="scene__name">
              <span>{scene.name}</span>
            </div>
          </div>);
      });

    return <OwlCarousel
      className="scenes-nav"
      options={{
        autoWidth: true,
        nav: true,
        startPosition,
        itemElement: 'span'
      }}
      events={{
        onDragged: this.updateCarouselPosition,
        onTranslated: this.updateCarouselPosition
      }}
    >
      {scenesElement}
    </OwlCarousel>;
  }
}

export default WithDependency(ScenesNav);
