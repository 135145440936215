import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = () => ({
  snackbar: {
    marginTop: '50px',
  },
  snackbarContent: {
    color: '#fff',
    background: '#008AAE',
    fontSize: '16px',
  },
});

function SnackbarComponent({message, classes, ...props}) {
  const { onClose } = props;
  return (
    <Snackbar className={classes.snackbar} {...props}>
          <SnackbarContent
            className={classes.snackbarContent}
            message={message}
            action={[
              <IconButton
                className={classes.button}
                key="close"
                aria-label="close"
                color="inherit"
                onClick={()=>{onClose()}}>
                <CloseIcon />
              </IconButton>,
            ]}/>
    </Snackbar>
  );
}

SnackbarComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  autoHideDuration: PropTypes.number,
};

SnackbarComponent.defaultProps = {
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  }
};

export default withStyles(styles)(SnackbarComponent);
