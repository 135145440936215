import React, {Component} from 'react';
import {translate} from 'react-i18next';
import IconLogo from 'static/home/icons/logo.svg'
import './index.scss';
import IconSent from 'static/home/icons/sent-check.svg';
import Modal from 'components/modal/index.jsx';
import ReactQueryParams from 'react-query-params';
import Classnames from 'classnames';
import { Form } from 'react-form';
import HintTextField from 'components/forms/hint-text-field';
import { Validation } from 'shared/Validation';
import { WithDependency } from 'shared/DependencyContext';

class SetPasswordModal extends ReactQueryParams {
  constructor(props) {
    super(props);
  }
  onSubmit(values) {
    const { password } = values;

    this.props.onSubmit({
      data: {
        newPassword: password,
        hash: this.queryParams.resetPassword,
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user.errorCode && nextProps.user.errorCode !== 'EMAIL_ALREADY_REGISTERED') {
      if (!this.props.user.errorCode && nextProps.user.errorCode && nextProps.user.errorCode !== 'EMAIL_ALREADY_REGISTERED') {
        let message = this.props.t('server.' + nextProps.user.errorCode);
        this.props.notifications.add(message);
      }
      if (!this.props.user.errorMsg && nextProps.user.errorMsg) {
        this.props.notifications.add(nextProps.user.errorMsg);
      }
    }
  }
  render() {
    const { t, open, user, onClose } = this.props;
    let statusClass = Classnames({
        'status': true,
        'status--opened': !!(user && user.changedPasswordSent),
        'status--closed': !(user && user.changedPasswordSent),
      }),
      validatePasswords = (values) => {
        return (passwordConfirmation) => Validation.passwordsEqual(values.password, passwordConfirmation);
      };

    return (
      <Modal open={open} onClose={onClose} className="set-password-modal" >
        <Form onSubmit={submittedValues => this.onSubmit(submittedValues)} validateOnSubmit={true}>
          {formApi => (
            <form onSubmit={formApi.submitForm}>
              <h3>{t('setPassword.header')}</h3>
              <HintTextField
                hintText={t('setPassword.password')}
                field="password"
                id="password"
                type="password"
                validate={Validation.password}
              />
              <HintTextField
                hintText={t('setPassword.passwordConfirmation')}
                field="passwordConfirmation"
                id="passwordConfirmation"
                type="password"
                validate={validatePasswords(formApi.values)}
              />
              <button type="submit" className="button">{t('setPassword.send')}</button>
              <div className={statusClass}><IconSent></IconSent><p>{t('setPassword.successMsg')}</p></div>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

export default translate("translations")(WithDependency(SetPasswordModal));
